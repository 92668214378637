@import url('https://fonts.googleapis.com/css2?family=Nixie+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Megrim&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');


body {
  /*overflow: hidden; !* Hide scrollbars *!*/
  overflow: scroll;
  font-size: 16px;
  /*background: #fdeeee;*/
  /*background-image: url("../img/first-aid-kit-1920x1080.png")*/
  /*background-image: url("../img/debut-light-1920x1080.png")*/
  background-image: url("../img/groovepaper-1920x1080.png")
  /*background-image: url("../img/inflicted-1920x1080.png")*/
}

/*--------------------------------------------------------------
# Hero & Header
--------------------------------------------------------------*/

.my-name {
  font-size: 2.5rem;
  font-family: 'Nixie One', cursive;
  margin-left: 1rem;
  /*font-family: 'Megrim', cursive;*/
  /*font-family: 'Sofia', cursive;*/
  border: 0;
  outline: none;
  color: black;
}

.my-name:hover {
  /*text-decoration: underline;*/
  color: black;
  /*font-weight: bold;*/
  /*font-family: 'Megrim', cursive;*/
}

.my-name:focus {
  box-shadow: none;
  outline: none !important;
}

.name-container {
  width: 20rem;
  transition: width 0s 10s linear;
}

.header-middle {
  align-items: center;
  display: flex;
  height: 82vh;
  justify-content: center;
  left: 0;
  position: inherit;
  top: 0;
  width: 100%;
  transition: height .2s .2s linear;
}

.header-top {
  /*text-align: center;*/
  align-items: center;
  display: flex;
  /*margin-top: 2rem;*/
  height: 8rem;
  justify-content: center;
  left: 0;
  position: inherit;
  top: 0;
  width: 100%;
  transition: height .3s .3s linear;
}

.header-links {
  font-size: 1.5rem;
  font-family: 'Nixie One', cursive;
  font-weight: bold;
  color: #676767;
  margin-left: 1rem;
  vertical-align: text-bottom;
  /*font-family: 'Megrim', cursive;*/
  /*font-family: 'Sofia', cursive;*/
}

.header-links:hover {
  text-decoration: underline;
  color: black;
}

.header-links:focus {
  box-shadow: none;
}



/*--------------------------------------------------------------
# Universal
--------------------------------------------------------------*/

.center-div {
  display: flex;
  justify-content: center;
}

.every-container {
  height: 100%;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/

.about-container {
  /*--bg-opacity: 1;*/
  /*background-color: rgba(255,232,220,var(--bg-opacity));*/
  /*background: #ffecec;*/
  border: 1px solid #dbdbdb;
  /*border-color: cornsilk;*/
  border-radius: 25px;
  /*background: #fae7e7;*/
  background: white;
  margin-top: 4vh;
  margin-bottom: 15vh;
  margin-right: 25vw;
  margin-left: -2vw;
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.bio {
  margin-top: 3rem;
  margin-left: 5rem;
  margin-right: 20rem;
}

.img-container {
  position: absolute;
  top: 40vh;
  left: 61vw;
  height: 480px;
  width: 360px;
  /*border-radius: 50%;*/
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background: transparent;
  /*background: url("./img/cities/grapes-jodie-morgan.jpg");*/
  z-index: 1;
  color: black;
  border: #e43c5c;

  /*background-color: rgb(255, 191, 191);*/
  /*opacity:0;*/
  /*visibility: hidden;*/
  /*transition: visibility 0s 1.5s, opacity 1.5s linear, background-color 2s linear;;*/
}

.title-container {
  font-size: 2.5rem;
  text-align: center;
  font-family: 'Nixie One', cursive;
  /*font-family: 'Megrim', cursive;*/
  /*font-family: 'Sofia', cursive;*/
}

@media (max-width: 768px) {
  .about-container {
    margin-top: 4vh;
    margin-bottom: 0vh;
    margin-right: 0vw;
    margin-left: 0vw;
    padding-top: 8vh;
    padding-bottom: 8vh;
  }

  .title-container {
    font-size: 1.5rem;
    text-align: center;
    font-family: 'Nixie One', cursive;
    margin-left: 1rem;
    margin-right: 1rem;
    /*font-family: 'Megrim', cursive;*/
    /*font-family: 'Sofia', cursive;*/
  }

  .bio {
    margin-top: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .img-container {
    display: none;
    /*position: absolute;*/
    /*top: 40vh;*/
    /*left: 61vw;*/
    /*height: 480px;*/
    /*width: 360px;*/
    /*!*border-radius: 50%;*!*/
    /*!*display: flex;*!*/
    /*flex-direction: column;*/
    /*word-wrap: break-word;*/
    /*background: transparent;*/
    /*z-index: 1;*/
    /*color: black;*/
    /*border: #e43c5c;*/
  }

}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact-container {
  border-radius: 25px;
  border: 1px solid #dbdbdb;
  /*background: #fae7e7;*/
  background: white;
  /*background-image: url("../img/first-aid-kit-1920x1080.png");*/
  margin-top: 4vh;
  margin-right: 25vw;
  margin-left: 25vw;
  /*height: 100%;*/
  padding-top: 2rem;
  /*padding-bottom: 5rem;*/
}

.contact-form-title {
  margin-bottom: .5em;
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  font-family: 'Nixie One', cursive;
}

.message-form {
  display: flex;
  justify-content: center;
}

.message-form-label {
  font-size: 1.5rem;
  font-family: 'Nixie One', cursive;
}

@media (max-width: 768px) {
  .contact-container {
    margin-right: 0vw;
    margin-left: 0vw;
  }

  .contact-form-title {
    display: flex;
    font-size: 1.7rem;
  }

  .message-form-label {
    font-size: 1.2rem;
    font-family: 'Nixie One', cursive;
  }

  .message-form {
    margin-right: 3.5vw;
    margin-left: 3.5vw;
  }

}

/*--------------------------------------------------------------
# Work
--------------------------------------------------------------*/

.work-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-right: 10vw;*/
  /*margin-left: 10vw;*/
  height: 70vh;
  padding-top: 2rem;
  /*padding-bottom: 5rem;*/
}

.work-image {
  border-radius: 25px;
  width: 550px;
  display: block;
}

.work-title {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-family: 'Nixie One', cursive;
}

@media (max-width: 768px) {
  .work-container {
    height: 65vh;
    padding-top: 0rem;
  }

  .work-image {
    width: 300px;
  }

  .work-title {
    font-size: 1.3rem;
  }

}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/

.skill-body {
  height: 100%;
  overflow-x: hidden;
}

.skill-container {
  border-radius: 25px;
  border: 1px solid #dbdbdb;
  /*background: #fae7e7;*/
  background: white;
  margin-top: 4vh;
  margin-left: 25vw;
  margin-right: -2vw;
  /*height: 100vh;*/
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.skills-text-header {
  font-weight: bold;
  font-size: 2.5rem;
  text-align: center;
  font-family: 'Nixie One', cursive;
  /*font-family: 'Megrim', cursive;*/
  /*font-family: 'Sofia', cursive;*/
}

.skills-text {
  font-size: 2.5rem;
  text-align: center;
  font-family: 'Nixie One', cursive;
  /*font-family: 'Megrim', cursive;*/
  /*font-family: 'Sofia', cursive;*/
}

@media (max-width: 768px) {
  .skill-body {
    height: 100%;
    overflow-x: hidden;
  }

  .skill-container {
    margin-left: 0vw;
    margin-right: 0vw;
  }

  .skills-text-header {
    font-weight: bold;
    font-size: 1.8rem;
  }

  .skills-text {
    font-size: 1.6rem;
    text-align: center;
  }

}

/*--------------------------------------------------------------
# Loader
--------------------------------------------------------------*/

.preloader {
  align-items: center;
  /*background: rgb(23, 22, 22);*/
  background: rgb(255, 218, 218);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  /*transition: opacity 0.3s linear;*/
  width: 100%;
  z-index: 9999;

  opacity:1;
  /*transition: visibility 0s 5s, opacity 2s linear;*/
  /*width:100px;*/
  /*height:100px;*/
  /*transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;*/
  /*transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 2s linear;*/

}

.fade-out{
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  background-color: transparent;
  /*background-color: rgb(255, 191, 191);*/
  opacity:0;
  visibility: hidden;
  transition: visibility 0s .7s, opacity .7s linear, background-color 2s linear;

}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.icon-container {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: inherit;
  /*top: 0;*/
  width: 100%;
  transition: height .3s .3s linear;
}

.icon-links {
  color: #676767;
}

.icon-links:hover {
  text-decoration: underline;
  color: black;
}

.icon-links:focus {
  box-shadow: none;
}

.footer {
  /*padding-top: 100%;*/
  margin-top: 4vh;
  margin-bottom: 0;
  /*position: absolute;*/
  width: 100%;
}

@media (max-width: 768px) {
  .footer {
    margin-top: 0vh;
  }
}